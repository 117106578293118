<template>
  <div class="preloader-area" id="preloader" v-if="isLoading">
    <div class="loader">
      <div class="waviy">
        <span>C</span>
        <span>D</span>
        <span>E</span>
        <span>V</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PreLoader",
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
});
</script>

<style lang="scss" scoped>
.preloader-area {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  text-align: center;
  background-color: var(--whiteColor);
  overflow-x: hidden;
}
.preloader-area .loader {
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  top: 50%;
  left: 0;
}
.preloader-area .waviy {
  margin-top: 20px;
  position: relative;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}
.preloader-area .waviy span {
  position: relative;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  display: inline-block;
  color: var(--titleColor);
  -webkit-animation: waviy 1s infinite;
  animation: waviy 1s infinite;
}
.preloader-area .waviy span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.preloader-area .waviy span:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.preloader-area .waviy span:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
@-webkit-keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}
</style>