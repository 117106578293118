<template>
  <NavbarFive class="home_one" />
  <ResponsiveNavbarFive class="home_one" />
  <PageBanner pageTitle="Terms & Conditions" mainTitle="Terms & Conditions" />
  <TermsConditions />
  <DownloadTheApp />
  <MainFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarFive from "../components/Layouts/NavbarFive.vue";
import ResponsiveNavbarFive from "../components/Layouts/ResponsiveNavbarFive.vue";
import PageBanner from "../components/Common/PageBanner.vue";
import TermsConditions from "../components/TermsConditions/TermsConditions.vue";
import DownloadTheApp from "../components/Common/DownloadTheApp.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "TermsConditionsPage",
  components: {
    NavbarFive,
    ResponsiveNavbarFive,
    PageBanner,
    TermsConditions,
    DownloadTheApp,
    MainFooter,
  },
});
</script>