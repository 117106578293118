<template>
  <div id="download-area" class="bg_secondary bg-cod pt-100 pb-100">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-6 offset-xl-3 px-xxl-6"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <h2 class="section-title text-white text-center mb-50">
            Download Our Mobile App Today For Free
          </h2>
        </div>
      </div>
      <div class="row home_seven-app-wraper position-relative">
        <div
          class="col-lg-6"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <div
            class="home_seven-app-card d-md-flex align-items-center round-10 overflow-hidden mb-30"
          >
            <div class="app-content">
              <h3 class="fs-34 text-white">From Android</h3>
              <p class="mb-25 text-offwhite">
                Install AI Caption and share photos with fascinating captioned with your friends!
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.cdev.ai_caption"
                class="google-playstore round-6 btn style-one"
              >
                <img
                  src="../../assets/img/playstore.webp"
                  alt="Image"
                  class="transition"
                />
              </a>
            </div>
            <div class="app-img">
              <img
                src="../../assets/img/home/graphic-display-6.png"
                alt="Image"
                class="d-block ms-auto app-img"
              />
            </div>
          </div>
        </div>
        <div
          class="col-lg-6"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div
            class="home_seven-app-card d-md-flex align-items-center round-10 overflow-hidden mb-30"
          >
            <div class="app-content">
              <h3 class="fs-34 text-white">From iOS</h3>
              <p class="mb-25 text-offwhite">
                Install AI Caption and share photos with fascinating captioned with your friends!
              </p>
              <a
                href="https://apps.apple.com/us/app/ai-caption-image-caption/id6475199122"
                class="apple-playstore round-6 btn style-four"
              >
                <img
                  src="../../assets/img/appstore.webp"
                  alt="Image"
                  class="transition"
                />
              </a>
            </div>
            <div class="app-img">
              <img
                src="../../assets/img/home/graphic-display-7.png"
                alt="Image"
                class="d-block ms-auto app-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadOurMobileApp",
};
</script>

<style lang="scss" scoped>
.home_seven-app-card {
  background-color: #32294d;
  position: relative;
  z-index: 1;
  padding: 40px 30px 0 30px;
  .app-img {
    max-width: 180px;
  }
  .app-content {
    width: calc(100% - 180px);
    padding-right: 25px;
    padding-bottom: 36px;
    p {
      margin-bottom: 28px;
    }
  }
  a {
    display: inline-block;
    &:hover {
      background-color: #f3795d;
      border-color: transparent;
    }
    &.google-playstore {
      padding: 11px 29px 10px;
      &:hover {
        border-color: transparent;
        background-color: var(--bruntColor);
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
    &.apple-playstore {
      border-radius: 6px;
      padding: 12px 29px 11px;
      background-color: var(--bruntColor);
      &:hover {
        background-color: var(--titleColor);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_seven-app-card {
    padding: 40px 20px 0 20px;
    .app-img {
      max-width: 100%;
      width: 100%;
      img {
        margin: 0 auto;
        display: block;
        max-width: 320px;
      }
    }
    .app-content {
      margin-bottom: 30px;
      width: 100%;
      padding: 0;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_seven-app-card {
    padding: 40px 50px 0 50px;
    .app-img {
      width: 234px;
      max-width: 100%;
    }
    .app-content {
      width: calc(100% - 225px);
    }
  }
}
</style>