<template>
  <div class="container home_seven-wh-wrapper style-one pb-100">
    <div class="row align-items-center">
      <div
        class="col-lg-6"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <div class="wh-img">
          <img
            src="../../assets/img/home/graphic-display-3.png"
            alt="Image"
          />
          <img
            src="../../assets/img/home/about/shape-1.webp"
            alt="Image"
            class="wh-img-shape"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="wh-content">
          <h2
            class="section-title mb-15"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            AI Caption Is Working Faster With Powerful AI
          </h2>
          <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
            With the power of AI, you can quickly generate a variety of captions suitable for any of your photos, 
            from food and people to landscapes.
          </p>
          <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
            Moreover, the application integrates with up to 47 popular languages.
          </p>
          <div class="feature-item-wrap">
            <div
              class="feature-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <h3 class="fs-20 fw-semibold position-relative">
                <img
                  src="../../assets/img/icons/check-2.svg"
                  alt="Image"
                  class="position-absolute start-0"
                />
                Intuitive UI
              </h3>
              <p class="mb-0">
                Intuitive UI, focusing on the user experience. With a modern and simple design, 
                AI Caption delivers an excellent and user-friendly experience for everyone.
              </p>
            </div>
            <div
              class="feature-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <h3 class="fs-20 fw-semibold position-relative">
                <img
                  src="../../assets/img/icons/check-2.svg"
                  alt="Image"
                  class="position-absolute start-0"
                />
                Less Action
              </h3>
              <p class="mb-0">
                With just one touch, users can generate multiple captions for the provided image. 
                All actions require simple swipes and touches, no typing, no filling out forms, just a touch.
              </p>
            </div>
          </div>
          <a
              href="#download-area"
              class="btn style-one"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              Get Download And Explore
            </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container home_seven-wh-wrapper style-two pb-100">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="wh-content">
          <h2
            class="section-title mb-15"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            AI Caption Is Working Faster With Powerful AI
          </h2>
          <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
            Share captions to social media easily and quickly. 
            No need for too many steps, just select the caption you want to share and the social media platform to share on. 
            The image and caption will be filled in automatically for you instantly.
          </p>
          <div class="feature-item-wrap">
            <div
              class="feature-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <h3 class="fs-20 fw-semibold position-relative">
                <img
                  src="../../assets/img/icons/check-2.svg"
                  alt="Image"
                  class="position-absolute start-0"
                />Lightspeed Sharing
              </h3>
              <p class="mb-0">
                Integrated with popular social networks, and additionally, 
                you can choose any social network you want to share on as long as the app of that social network is on your phone.
              </p>
            </div>
            <div
              class="feature-item"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
            >
              <h3 class="fs-20 fw-semibold position-relative">
                <img
                  src="../../assets/img/icons/check-2.svg"
                  alt="Image"
                  class="position-absolute start-0"
                />Captions History
              </h3>
              <p class="mb-0">
                All the captions you generated will be saved for easy reuse later, and the app also highlights shared captions for easy tracking. 
                You won't have to worry about forgetting anymore.
              </p>
            </div>
          </div>
          <a
              href="#download-area"
              class="btn style-one"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              Get Download And Explore
            </a>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="wh-img"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <img
            src="../../assets/img/home/graphic-display-4.png"
            alt="Image"
            class="position-relative index-1"
          />
          <img
            src="../../assets/img/home/about/shape-1.webp"
            alt="Image"
            class="wh-shape-one moveHorizontal"
          />
          <img
            src="../../assets/img/home/about/shape-2.webp"
            alt="Image"
            class="wh-shape-two bounce"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WorkingFasterWithPowerfulTools",
};
</script>

<style lang="scss" scoped>
.home_seven-wh-wrapper {
  .wh-content {
    h2 {
      font-size: 50px;
    }
  }
  .feature-item-wrap {
    margin: 30px 0 40px;
    .feature-item {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      h3 {
        padding-left: 35px;
        margin-bottom: 16px;
      }
    }
  }
  &.style-one {
    .wh-img {
      position: relative;
      .wh-img-shape {
        position: absolute;
        top: 26px;
        right: 20%;
      }
    }
  }
  &.style-two {
    .wh-img {
      position: relative;

      .wh-shape-one {
        position: absolute;
        top: 26px;
        left: 34%;
      }
      .wh-shape-two {
        position: absolute;
        bottom: 20px;
        left: 0;
        z-index: 1;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_seven-wh-wrapper {
    .feature-item-wrap {
      margin: 20px 0 25px;
      .feature-item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .home_seven-wh-wrapper {
    &.style-one {
      .wh-img {
        margin-bottom: 30px;
      }
    }
    &.style-two {
      .wh-img {
        margin-top: 30px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_seven-wh-wrapper {
    &.style-one {
      .wh-img {
        width: calc(100% + 50px);
      }
      .wh-content {
        width: calc(100% - 120px);
        margin-left: auto;
      }
    }
    &.style-two {
      .wh-img {
        width: calc(100% + 50px);
        margin-left: -45px;
      }
      .wh-content {
        width: calc(100% - 120px);
        margin-right: auto;
      }
    }
  }
}
</style>