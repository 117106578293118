<template>
  <div
    id="feature-area"
    class="container home_seven-featured-wrapper pt-100 pb-70 mn-section"
  >
    <div class="row">
      <div
        class="col-xl-6 offset-xl-3 col-md-8 offset-md-2"
        data-cue="slideInUp"
      >
        <h2 class="section-title mb-35 text-center">
          Why AI Caption Is The Right Choice For You
        </h2>
      </div>
    </div>
    <div class="row justify-content-center gx-xxl-5">
      <div
        class="col-xxl-3 col-lg-4 col-md-6"
        v-for="feature in features"
        :key="feature.id"
        data-aos="fade-up"
        data-aos-duration="1000"
        :data-aos-delay="feature.dataDelay"
      >
        <div class="featured-card">
          <span
            class="feature-icon d-flex flex-column justify-content-center align-items-center"
          >
            <img :src="feature.iconImage" alt="Image" />
          </span>
          <h3 class="fs-20">
            {{ feature.title }}
          </h3>
          <p class="mb-0">
            {{ feature.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "WhyChoiceForYou",
  data() {
    return {
      features: [
        {
          id: 1,
          dataDelay: "200",
          iconImage: require("../../assets/img/icons/cube.svg"),
          title: "Diverse Genres & Tones",
          description:
            "With various options for genres and tones, you can customize so that AI can generate captions more suitable for the image.",
        },
        {
          id: 2,
          dataDelay: "300",
          iconImage: require("../../assets/img/icons/timer.svg"),
          title: "Fast & Easy",
          description:
            "With a single touch, you can instantly create numerous captions suitable for your photos.",
        },
        {
          id: 3,
          dataDelay: "400",
          iconImage: require("../../assets/img/icons/language.svg"),
          title: "Languages",
          description:
            "Supporting up to 47 languages, indulge in the virtual world on social media without the fear of language barriers.",
        },
        {
          id: 4,
          dataDelay: "500",
          iconImage: require("../../assets/img/icons/quote-3.svg"),
          title: "Sharing",
          description:
            "Quickly share images with captions from the app to social media without the need for too many steps.",
        },
        {
          id: 5,
          dataDelay: "600",
          iconImage: require("../../assets/img/icons/files.svg"),
          title: "History",
          description:
            "Save every caption you've ever created, making it easy to reuse later. Highlight shared captions for easy tracking.",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.home_seven-featured-wrapper {
  .featured-card {
    margin-bottom: 30px;
    .feature-icon {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      margin-bottom: 22px;
      border: 1px solid rgba(167, 97, 248, 0.1);
      transition: var(--transition);
      img {
        transition: var(--transition);
        filter: brightness(1) invert(0);
      }
    }
    h3 {
      margin-bottom: 10px;
    }
    p {
      padding-right: 5px;
    }
    &:hover {
      .feature-icon {
        background-color: var(--primaryColor);
        border-color: transparent;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
</style>