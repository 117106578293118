<template>
  <NavbarThree class="home_seven" />
  <ResponsiveNavbarThree class="home_seven" />
  <MainBanner />
  <YourGatewayToReliable />
  <WhyChoiceForYou />
  <WorkingFasterWithPowerfulTools />
  <DownloadOurMobileApp />
  <JoinWithUs />
  <FooterTwo />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarThree from "../components/Home/NavbarThree.vue";
import ResponsiveNavbarThree from "../components/Home/ResponsiveNavbarThree.vue";
import MainBanner from "../components/Home/MainBanner.vue";
import YourGatewayToReliable from "../components/Home/YourGatewayToReliable.vue";
import WhyChoiceForYou from "../components/Home/WhyChoiceForYou.vue";
import WorkingFasterWithPowerfulTools from "../components/Home/WorkingFasterWithPowerfulTools.vue";
import DownloadOurMobileApp from "../components/Home/DownloadOurMobileApp.vue";
import JoinWithUs from "../components/Home/JoinWithUs.vue";
import FooterTwo from "../components/Home/FooterTwo.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    NavbarThree,
    ResponsiveNavbarThree,
    MainBanner,
    YourGatewayToReliable,
    WhyChoiceForYou,
    WorkingFasterWithPowerfulTools,
    DownloadOurMobileApp,
    JoinWithUs,
    FooterTwo,
  },
});
</script>