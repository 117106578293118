<template>
  <div class="terms-wrap ptb-100">
    <div class="container">
      <div class="single-para">
        <h3>Legal Disclaimer:</h3>
        <p>
          We may collect personal identification information from Users in a
          variety of ways, including, but not limited to, when Users visit our
          site, subscribe to the newsletter, fill out a form, and in connection
          with other <strong>activities</strong>, services, features or
          resources we make available on our Site. Users may be asked for, as
          appropriate, name, email address, mailing address, phone number,
          <router-link to="/">company name</router-link>. We will collect
          personal identification information from Users only if they
          voluntarily consent such information to us. Users can always refuse to
          supply personally identification information, except that it may
          prevent them from engaging in certain Site related.
        </p>
      </div>
      <div class="single-para">
        <h3>Credit Reporting Terms of Service</h3>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum, quod.
          Ratione ex delectus quis tenetur odio non alias numquam official ipsum
          dolor sit, amet consectetur adipisicing elit. Accusamus, laborum.
        </p>
        <ol>
          <li>
            Mauris ut in vestibulum hasellus ultrices fusce nibh justo,
            venenatis, amet. Lectus quam in lobortis.
          </li>
          <li>
            Consectetur phasellus <strong>ultrices</strong> fusce nibh justo,
            venenatis, amet. Lectus quam in lobortis justo venenatis amet.
          </li>
          <li>
            Lectus quam there are two thing is very important in Consectetur
            phasellus ultrices fusce nibh justo, venenatis, amet in lobortis.
          </li>
          <li>
            Web Development very creative to do something , mauris ut in
            vestibulum. Consectetur phasellus ultrices fusce nibh justo,
            venenatis, amet to all design.
          </li>
        </ol>
      </div>
      <div class="single-para">
        <h3>Ownership of Site Agreement to Terms of Use</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla
          minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint
          tempore, eius molestias aliquam debitis. Neque saepe dignissimos
          repudiandae fuga.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil
          eveniet quas dignissimos doloribus ea pariatur corrupti rerum
          deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero
          neque reiciendis cupiditate dolores nam. Earum eius similique
          sapiente. Iure, sit non. At fuga ipsam veniam.
        </p>
      </div>
      <div class="single-para">
        <h3>Provision of Services</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil
          eveniet quas dignissimos doloribus ea pariatur corrupti rerum
          deserunt, ipsum, ipsa eos veniam aspernatur fuga, optio soluta? Libero
          neque reiciendis cupiditate dolores nam. Earum eius similique
          sapiente. Iure, sit non. At fuga ipsam veniam.
        </p>
        <ul class="feature-list style2 list-style">
          <li>Lorem ipsum dolor, sit amet.</li>
          <li>Adipisicing elit. Officia, odit!</li>
          <li>Aquaerat ipsa quis possimus.</li>
          <li>Lorem, ipsum dolor sit amet consectetur adipi.</li>
          <li>Consectetur adipisicing elit. Voluptatibus, dignissimos.</li>
          <li>Highly professional administration.</li>
        </ul>
      </div>
      <div class="single-para">
        <h3>Limitation of Liability</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla
          minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint
          tempore, eius molestias aliquam debitis. Neque saepe dignissimos
          repudiandae fuga.
        </p>
      </div>
      <div class="single-para">
        <h3>Accounts, Passwords and Security</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime nulla
          minus quasi. Voluptatem, facilis saepe ullam autem magni quod sint
          tempore, eius molestias aliquam debitis. Neque saepe dignissimos
          repudiandae fuga.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nihil
          eveniet quas dignissimos doloribus ea pariatur corrupti ullam autem
          magni quod sint tempore saepe ullam autem magni.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>