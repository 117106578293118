<template>
  <div class="error-wrap ptb-100">
    <div class="pt-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="error-content">
              <img src="../assets/img/404.webp" alt="image" />
              <h2 class="fs-34 mb-20">Oops! Page Not Found</h2>
              <p>
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable.
              </p>
              <router-link to="/" class="btn style-one">
                Back To home
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>