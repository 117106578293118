<template>
  <div
    class="home_seven-join-wrapper position-relative index-1"
    data-cue="slideInUp"
  >
    <div class="container">
      <div
        class="join-box bg-grad round-6 ptb-120 position-relative overflow-hidden"
      >
        <img
          src="../../assets/img/home/join-bg-shape.webp"
          alt="Image"
          class="join-bg-shape position-absolute"
        />
        <img
          src="../../assets/img/home/join-shape-2.webp"
          alt="Image"
          class="join-shape-two position-absolute sm-none"
        />
        <div class="row">
          <div class="col-xxl-6 col-xl-5 col-lg-6">
            <div class="join-img-wrap position-relative">
              <img
                src="../../assets/img/home/graphic-display-5.png"
                alt="Image"
                width="320"
                class="join-img"
              />
              <img
                src="../../assets/img/home/join-shape-3.webp"
                alt="Image"
                class="join-img-shape position-absolute bounce"
              />
            </div>
          </div>
          <div
            class="col-xxl-5 offset-xxl-1 col-xl-6 offset-xl-1 col-lg-5 offset-lg-1 ps-xxl-0"
          >
            <div class="join-content">
              <h2 class="section-title text-white mb-30">
                Join With Us. 1000+ Users Already Using AI Caption.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinWithUs",
};
</script>

<style lang="scss" scoped>
.home_seven-join-wrapper {
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 50%;
    background-color: var(--secondaryColor);
    z-index: -1;
  }
  .join-box {
    padding: 0 85px 0 85px;
    .join-content {
      padding: 115px 0;
    }
    .join-bg-shape {
      top: 0;
      left: 0;
      height: 100%;
    }
    .join-shape-two {
      bottom: 65px;
      right: 28px;
    }
    .join-img-wrap {
      height: 100%;
      .join-img-shape {
        bottom: 5%;
        right: 20%;
        z-index: 0;
      }
      .join-img {
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        margin: 0 auto 0 0;
        filter: drop-shadow(0px 17px 67px rgba(6, 12, 69, 0.44));
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_seven-join-wrapper {
    .join-box {
      padding: 55px 20px 55px 20px;
      .join-img-wrap {
        margin-bottom: 30px;
        .join-img {
          position: relative;
          bottom: auto;
          left: auto;
          margin: 0 auto;
        }
      }
      .join-content {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven-join-wrapper {
    .join-box {
      padding: 70px 35px 70px 35px;
      .join-img-wrap {
        margin-bottom: 30px;
        .join-img {
          position: relative;
          bottom: auto;
          left: auto;
          margin: 0 auto;
        }
      }
      .join-content {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven-join-wrapper {
    .join-box {
      padding: 30px 30px 0;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home_seven-join-wrapper {
    .join-box {
      padding-top: 20px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_seven-join-wrapper {
    .join-box {
      .join-content {
        margin-left: -25px;
      }
    }
  }
}
</style>