<template>
  <div id="about-area" class="container pt-100 mn-section">
    <div class="home_seven-simple-wrapper bg_secondary round-10">
      <div class="row">
        <div class="col-lg-6 pe-xxl-0">
          <div class="simple-content">
            <h2
              class="section-title text-white"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              Let's Install And Sign-In To Get 3 Free Credits Now
            </h2>
            <p
              class="text-offwhite mb-40"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
            With each credit, you can generate 10 different captions from AI.
            You can also purchase additional credits if you have used up all the available ones.
            </p>
            <a
              href="#download-area"
              class="btn style-one"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              Get Download And Explore
            </a>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="simple-img-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
          <!-- Put the image of list generated captions with preview image -->
            <img
              src="../../assets/img/home/shape-1.webp"
              alt="Image"
              class="simple-shape position-absolute bounce"
            />
            <img
              src="../../assets/img/home/graphic-display-2.png"
              alt="Image"
              width="700"
              class="simple-img index-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YourGatewayToReliable",
};
</script>

<style lang="scss" scoped>
.home_seven-simple-wrapper {
  position: relative;
  padding: 100px 50px;
  .simple-content {
    h2 {
      margin-bottom: 21px;
    }
    p {
      margin-bottom: 43px;
    }
  }
  .simple-img-wrap {
    position: absolute;
    bottom: 0;
    right: 54px;
    .simple-shape {
      position: absolute;
      top: 29%;
      left: 21.5%;
      z-index: 0;
    }
    .simple-img {
      position: relative;
      z-index: 2;
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_seven-simple-wrapper {
    padding: 55px 20px 0;
  }
}

@media only screen and (max-width: 991px) {
  .home_seven-simple-wrapper {
    .simple-img-wrap {
      position: relative;
      margin-top: 30px;
      right: auto;
      .simple-img {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven-simple-wrapper {
    padding: 70px 50px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven-simple-wrapper {
    .simple-img-wrap {
      max-width: 450px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home_seven-simple-wrapper {
    .simple-img-wrap {
      max-width: 540px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_seven-simple-wrapper {
    padding: 100px 105px;
  }
}
</style>