<template>
  <div class="app-wrapper position-relative index-1">
    <div class="container">
      <div
        class="bg-grad round-6 app-box text-center"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <div class="row">
          <div
            class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1"
          >
            <h2 class="section-title text-white">
              Download The App & Invest Dreams Today
            </h2>
            <p class="text-offwhite">
              Start selling online from your store or via link. Receive payments
            </p>
            <div class="app-btn">
              <a
                href="https://play.google.com/store/games?hl=en&gl=US&pli=1"
                class="google-playstore"
                target="_blank"
              >
                <img
                  src="../../assets/img/home-one/playstore.webp"
                  alt="Image"
                />
              </a>
              <a
                href="https://www.apple.com/store"
                class="apple-playstore"
                target="_blank"
              >
                <img
                  src="../../assets/img/home-one/appstore.webp"
                  alt="Image"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadTheApp",
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  .app-box {
    padding: 110px 0 122px;
  }
  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 50%;
    background-color: var(--secondaryColor);
    z-index: -1;
  }
  &.style-two {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 50%;
      background-color: var(--magnoliaColor);
      z-index: -1;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin: 0 0 34px;
    font-weight: 300;
  }
  .app-btn {
    a {
      border: 1px solid var(--whiteColor);
      border-radius: 5px;
      padding: 11px 30px;
      display: inline-block;
      transition: var(--transition);
      img {
        transition: var(--transition);
      }
      &:first-child {
        margin-right: 20px;
      }
      &:hover {
        background-color: #f3795d;
        border-color: transparent;
      }
    }
    &.style-two {
      a {
        background: linear-gradient(92deg, #a861f9 0%, #8145d4 100%);
        border: none;
        &:before {
          background: var(--secondaryColor);
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .app-wrapper {
    .app-box {
      padding: 55px 20px 60px;
      .app-btn {
        a {
          padding: 12px 14px;
          &:first-child {
            margin-right: 10px;
          }
          img {
            max-width: 90px;
          }
        }
      }
    }
  }
}
</style>