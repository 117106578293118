<template>
  <div
    class="responsive-navbar offcanvas offcanvas-end border-0"
    data-bs-backdrop="static"
    tabindex="-1"
    id="navbarOffcanvas"
  >
    <div class="offcanvas-header">
      <router-link to="/" class="logo d-inline-block">
        <img class="logo-light" src="../../assets/img/logo.webp" alt="Image" />
        <img
          class="logo-dark"
          src="../../assets/img/logo-white.webp"
          alt="Image"
        />
      </router-link>
      <button
        type="button"
        class="close-btn bg-transparent lh-1 p-0"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ri-close-line"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <ul class="responsive-menu mobile-menu">
        <li class="responsive-menu-list nav-item">
          <router-link to="/" class="nav-link">Home</router-link>
        </li>
        <li class="responsive-menu-list nav-item">
          <router-link to="/about-us" class="nav-link">About Us</router-link>
        </li>
        <li class="responsive-menu-list nav-item">
          <router-link to="/pricing-plan" class="nav-link"
            >Pricing Plan</router-link
          >
        </li>
        <li class="responsive-menu-list nav-item">
          <router-link to="/faq" class="nav-link">FAQ</router-link>
        </li>
        <li class="responsive-menu-list nav-item">
          <router-link to="/contact-us" class="nav-link"
            >Contact Us</router-link
          >
        </li>
        <li
          :class="[
            'responsive-menu-list mobile-menu-child',
            { active: isActivePages },
          ]"
        >
          <a
            href="javascript:void(0)"
            class="dropdown-toggle"
            @click="toggleActivePages"
          >
            Pages
          </a>
          <ul class="responsive-menu-items">
            <li
              :class="[
                'responsive-menu-list mobile-menu-child',
                { active: isActivePortfolio },
              ]"
            >
              <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                @click="toggleActivePortfolio"
              >
                Portfolio
              </a>

              <ul class="responsive-menu-items">
                <li><router-link to="/portfolio">Portfolio</router-link></li>
                <li>
                  <router-link to="/portfolio-details">
                    Portfolio Details
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              :class="[
                'responsive-menu-list mobile-menu-child',
                { active: isActiveShop },
              ]"
            >
              <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                @click="toggleActiveShop"
              >
                Shop
              </a>

              <ul class="responsive-menu-items">
                <li>
                  <router-link to="/shop-left-sidebar">
                    Shop Left Sidebar
                  </router-link>
                </li>
                <li>
                  <router-link to="/shop-right-sidebar">
                    Shop Right Sidebar
                  </router-link>
                </li>
                <li><router-link to="/shop">Shop Grid</router-link></li>
                <li>
                  <router-link to="/product-details">
                    Product Details
                  </router-link>
                </li>
                <li><router-link to="/cart">Cart</router-link></li>
                <li><router-link to="/wishlist">Wishlist</router-link></li>
                <li><router-link to="/checkout">Checkout</router-link></li>
              </ul>
            </li>

            <li
              :class="[
                'responsive-menu-list mobile-menu-child',
                { active: isActiveBlog },
              ]"
            >
              <a
                href="javascript:void(0)"
                class="dropdown-toggle"
                @click="toggleActiveBlog"
              >
                Blog
              </a>

              <ul class="responsive-menu-items">
                <li>
                  <router-link to="/blog-left-sidebar">
                    Blog Left Sidebar
                  </router-link>
                </li>
                <li>
                  <router-link to="/blog-right-sidebar">
                    Blog Right Sidebar
                  </router-link>
                </li>
                <li>
                  <router-link to="/blog">Blog Grid</router-link>
                </li>
                <li>
                  <router-link to="/blog-details-left-sidebar">
                    Blog Details Left Sidebar
                  </router-link>
                </li>
                <li>
                  <router-link to="/blog-details-right-sidebar">
                    Blog Details Right Sidebar
                  </router-link>
                </li>
                <li>
                  <router-link to="/blog-details">
                    Blog Details No Sidebar
                  </router-link>
                </li>
              </ul>
            </li>
            <li><router-link to="/login">Login</router-link></li>
            <li><router-link to="/register">Register</router-link></li>
            <li>
              <router-link to="/terms-conditions">
                Terms & Conditions
              </router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">Privacy Policy</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="other-option d-md-flex align-items-center">
        <ul class="social-profile list-style text-md-center">
          <li>
            <a href="https://www.fb.com" target="_blank">
              <img src="../../assets/img/icons/facebook.svg" alt="Facebook" />
            </a>
          </li>
          <li>
            <a href="https://www.pinterest.com" target="_blank">
              <img src="../../assets/img/icons/pinterest.svg" alt="Linkedin" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com" target="_blank">
              <img src="../../assets/img/icons/instagram.svg" alt="Instagram" />
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com" target="_blank">
              <img src="../../assets/img/icons/twitter.svg" alt="Twitter" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "ResponsiveNavbarFive",
  data() {
    return {
      isActivePages: ref(false),
      isActivePortfolio: ref(false),
      isActiveShop: ref(false),
      isActiveBlog: ref(false),
    };
  },
  methods: {
    toggleActivePages() {
      this.isActivePages = !this.isActivePages;
    },
    toggleActivePortfolio() {
      this.isActivePortfolio = !this.isActivePortfolio;
    },
    toggleActiveShop() {
      this.isActiveShop = !this.isActiveShop;
    },
    toggleActiveBlog() {
      this.isActiveBlog = !this.isActiveBlog;
    },
  },
});
</script>

<style lang="scss" scoped>
.responsive-navbar {
  border: 0 !important;
  background-color: var(--magnoliaColor);
  padding: 30px 30px;
  padding: 20px 20px 25px;
  &.home_eight {
    .other-option {
      .option-item {
        .login-btn {
          display: block;
          width: 100%;
          text-align: center;
          font-weight: 500;
          padding: 13px 34px;
          border-radius: 50px;
          margin-bottom: 15px;
          transition: var(--transition);
          &:first-child {
            color: var(--menuColor);
            border: 1px solid var(--menuColor);
            margin-right: 20px;
            &:hover {
              background-color: var(--bruntColor);
              color: var(--whiteColor);
              border-color: transparent;
            }
          }
          &:last-child {
            background-color: var(--bruntColor);
            color: var(--whiteColor);
            &:hover {
              background-color: var(--titleColor);
            }
          }
        }
      }
    }
  }
  .close-btn {
    border-radius: 5px;
    border: 1px solid rgba(245, 245, 245, 0.3);
    background: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    line-height: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid rgba(167, 97, 248, 0.36);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    transition: var(--transition);
    &:hover {
      border-color: transparent;
      background-color: var(--primaryColor) !important;
      i {
        color: var(--whiteColor);
      }
    }
    i {
      color: var(--titleColor);
      font-size: 22px;
      line-height: 1;
      transition: var(--transition);
      margin: auto;
    }
  }
  .offcanvas-header {
    padding: 0;
    display: block;
  }
  .offcanvas-body {
    padding: 10px 0;
    ul {
      list-style-type: none;
      margin-bottom: 0;
      &.responsive-menu {
        padding-left: 0;
        overflow: hidden;
        li {
          border-bottom: 1px solid rgba(167, 97, 248, 0.36);
          position: relative;
          line-height: 55px;
          height: 55px;
          clear: both;
          width: 100%;
          a {
            height: 100%;
            display: block;
            color: var(--menuColor);
            padding-right: 40px;
            text-decoration: none;
            font: {
              size: 16px;
              weight: 500;
            }
            &:hover,
            &.active {
              color: var(--primaryColor);
            }
          }
          &.mobile-menu-child {
            &:after {
              top: 50%;
              right: 0;
              content: "\ea4e";
              color: var(--menuColor);
              position: absolute;
              transform: translateY(-50%);
              transition: var(--transition);
              cursor: pointer;
              font: {
                family: remixicon;
                size: 18px;
              }
            }
          }
          &.responsive-menu-list {
            border-bottom: 1px solid rgba(167, 97, 248, 0.16);
            position: relative;
            line-height: 55px;
            height: 55px;
            clear: both;
            width: 100%;
            a {
              height: 100%;
              display: block;
              color: var(-menuColor);
              padding-right: 40px;
              text-decoration: none;
              font: {
                size: 16px;
                weight: 500;
              }
              &:hover,
              &.active {
                color: var(--primaryColor);
              }
            }
            .dropdown-toggle {
              &:after {
                display: none;
              }
            }
            .responsive-menu-items {
              height: 0;
              overflow: hidden;
              a {
                font-size: 15px;
                border-bottom: 1px solid rgba(167, 97, 248, 0.16);
                &:hover,
                &.active {
                  color: var(--primaryColor);
                }
              }
            }
            &.without-icon {
              &:after {
                display: none;
              }
            }
            &.active {
              &:after {
                transform: translateY(-50%) rotate(180deg);
              }
              > .responsive-menu-items {
                transition: all 200ms;
                display: block;
                height: auto;
                clear: both;
                float: left;
                width: 100%;
                padding: 0 0 0 20px;
                li {
                  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                }
              }
              > a {
                color: var(--primaryColor);
              }
            }
          }
        }
      }
    }
    .other-option {
      margin-top: 30px;
    }
    .social-profile {
      margin-top: 30px;
      li {
        margin-right: 20px;
        display: inline-block;
        &:last-child {
          margin-right: 0;
        }
        a {
          position: relative;
          transition: var(--transition);
          img {
            transition: var(--transition);
          }
          &:hover {
            img {
              transform: translateY(-3px);
            }
          }
        }
      }
    }
  }
}
</style>