import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarFive = _resolveComponent("NavbarFive")!
  const _component_ResponsiveNavbarFive = _resolveComponent("ResponsiveNavbarFive")!
  const _component_PageBanner = _resolveComponent("PageBanner")!
  const _component_PrivacyPolicy = _resolveComponent("PrivacyPolicy")!
  const _component_DownloadTheApp = _resolveComponent("DownloadTheApp")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavbarFive, { class: "home_one" }),
    _createVNode(_component_ResponsiveNavbarFive, { class: "home_one" }),
    _createVNode(_component_PageBanner, {
      pageTitle: "Privacy Policy",
      mainTitle: "Privacy Policy"
    }),
    _createVNode(_component_PrivacyPolicy),
    _createVNode(_component_DownloadTheApp),
    _createVNode(_component_MainFooter)
  ], 64))
}