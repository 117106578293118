<template>
  <div
    id="hero-area"
    class="home_seven-hero-section position-relative mn-section"
  >
    <img
      src="../../assets/img/home/hero/shape-1.webp"
      alt="Image"
      class="hero-shape-one position-absolute bounce sm-none"
    />
    <img
      src="../../assets/img/home/hero/shape-2.webp"
      alt="Image"
      class="hero-shape-two position-absolute moveHorizontal sm-none"
    />
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1 px-xxl-6">
          <div class="hero-content text-center">
            <h1
              class="fs-52 fw-bold mb-50 text-white"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              AI Caption, Elevate your images with diverse
            </h1>
            <p
              class="fw-medium"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              This innovative application harnesses the power of artificial intelligence to generate captivating captions for your images.
            </p>
          </div>
        </div>
      </div>
      <div
        class="hero-img-wrap position-relative"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <img
          src="../../assets/img/home/graphic-display.png"
          alt="Image"
          class="hero-img-one d-block mx-auto bounce"
        />
        <!-- <img
          src="../../assets/img/home/hero/hero-img-2.webp"
          alt="Image"
          class="hero-img-two position-absolute"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>

<style lang="scss" scoped>
.home_seven-hero-section {
  padding: 225px 0 10px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 65.5%;
    z-index: -1;
    background-image: url(../../assets/img/home/hero/hero-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }
  .hero-shape-one {
    top: 19.5%;
    left: 11.4%;
  }
  .hero-shape-two {
    top: 31%;
    right: 14%;
  }
  .hero-content {
    h1 {
      font-size: 70px;
      line-height: 84px;
      margin-bottom: 8px;
    }
    p {
      color: rgba(255, 255, 255, 0.85);
      margin-bottom: 32px;
      letter-spacing: 0.4px;
    }
    .hero-btn {
      a {
        &:first-child {
          margin-right: 20px;
        }
        &.link {
          span {
            color: var(--whiteColor);
            text-decoration: underline;
          }
          img {
            position: relative;
            top: -3px;
            margin-left: 10px;
          }
          &:hover {
            span {
              color: var(--bruntColor);
            }
          }
        }
      }
    }
  }
  .hero-img-wrap {
    position: relative;
    padding-top: 63px;
    padding-left: 25px;
    .hero-img-two {
      top: 0;
      left: -10px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .home_seven-hero-section {
    .hero-content {
      .hero-btn {
        a {
          &:first-child {
            margin-right: 0;
            margin-bottom: 20px;
          }
          &.link {
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_seven-hero-section {
    padding: 145px 0 35px;
    &:before {
      height: 82%;
    }
    .hero-content {
      h1 {
        font-size: 34px;
        line-height: 44px;
      }
      p {
        padding: 0;
      }
    }
    .hero-img-wrap {
      padding-left: 45px;
      padding-top: 0;
      .hero-img-two {
        top: -20px;
        max-width: 90px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .home_seven-hero-section {
    .hero-content {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_seven-hero-section {
    padding: 180px 0 5px;
    .hero-shape-one {
      left: 3%;
    }
    .hero-shape-two {
      right: 3%;
    }
    .hero-content {
      h1 {
        font-size: 54px;
        line-height: 68px;
      }
      p {
        padding: 0 50px;
      }
    }
    .hero-img-wrap {
      padding-left: 65px;
      .hero-img-two {
        top: 20px;
        max-width: 150px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_seven-hero-section {
    padding: 200px 0 80px;
    .hero-shape-one {
      left: 6%;
    }
    .hero-shape-two {
      right: 6%;
    }
    .hero-img-wrap {
      padding-left: 65px;
      .hero-img-two {
        top: 20px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_seven-hero-section {
    .hero-content {
      h1 {
        font-size: 80px;
        line-height: 100px;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .home_seven-hero-section {
    .hero-img-wrap {
      padding-left: 58px;
      width: calc(100% + 65px);
      .hero-img-two {
        top: 12px;
        left: -83px;
      }
    }
  }
}
</style>