<template>
  <NavbarFive class="home_one" />
  <ResponsiveNavbarFive class="home_one" />
  <PageBanner pageTitle="Privacy Policy" mainTitle="Privacy Policy" />
  <PrivacyPolicy />
  <DownloadTheApp />
  <MainFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NavbarFive from "../components/Layouts/NavbarFive.vue";
import ResponsiveNavbarFive from "../components/Layouts/ResponsiveNavbarFive.vue";
import PageBanner from "../components/Common/PageBanner.vue";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy.vue";
import DownloadTheApp from "../components/Common/DownloadTheApp.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "PrivacyPolicyPage",
  components: {
    NavbarFive,
    ResponsiveNavbarFive,
    PageBanner,
    PrivacyPolicy,
    DownloadTheApp,
    MainFooter,
  },
});
</script>