<template>
  <PreLoader />
  <ThemeSwitcher />
  <router-view />
  <BackToTop />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PreLoader from "./components/Layouts/PreLoader.vue";
import ThemeSwitcher from "./components/Layouts/ThemeSwitcher.vue";
import BackToTop from "./components/Layouts/BackToTop.vue";

export default defineComponent({
  name: "App",
  components: {
    PreLoader,
    ThemeSwitcher,
    BackToTop,
  },
});
</script>