import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import Home from "../pages/HomePage.vue";
import TermsConditionsPage from "../pages/TermsConditionsPage.vue";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomePage",
    component: Home,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;